body {
  margin: 0;
  /* font-family: 'Menlo'; */
    /* font-style: normal; */
    /* font-weight: 500; */
    /* font-display: swap; */
    /* src: url(./fonts/Roboto/Roboto-Medium-webfont.woff) format('woff'); */
    font-family:Roboto ;
  src: url(./fonts/Roboto/Roboto-Medium-webfont.woff) format('woff2');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family:Roboto ;
  font-weight: 500;
  src: url(./fonts/Roboto/Roboto-Medium-webfont.woff) format('woff2');
}

/* scroll */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #063d6b;
  /* background: #6b6b6b; */
  border-radius: 10px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
